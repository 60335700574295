window.addEventListener('load', () => {
    burgerAction();
    popup();
    video();
    openSubmenu();
});

window.addEventListener('resize', () => {
});

window.addEventListener('scroll', () => {
});

const body = document.querySelector('body');

function popup() {
    const buttons = body.querySelectorAll('.js-open-form');

    buttons.forEach(button => {
        button.addEventListener('click', (e)=> {
            e.preventDefault();
            const id = button.dataset.id;
            const popup = body.querySelector('.js-popup'+id);
            const close = popup.querySelector('.close-popup');
            popup.classList.add('show');
            body.classList.add('no-scroll');

            close.addEventListener('click', ()=>{
                popup.classList.remove('show');
                body.classList.remove('no-scroll');
            })

            popup.addEventListener('click', (e)=> {
                const target = e.target;

                if (target === popup) {
                    popup.classList.remove('show');
                    body.classList.remove('no-scroll');                        
                }
            })
        })
    });
}

function video() {
    const videos = body.querySelectorAll('.video.file');

    videos.forEach(element => {
        if (element) {
            const video = element.querySelector('video');
            const button = element.querySelector('.button-play');
            video.currentTime = 1;

            element.addEventListener('click', (e)=> {
                const target = e.target;
                
                if (target != button) {
                    element.classList.remove('play');
                    video.pause();
                }
            });

            button.addEventListener('click', ()=> {
                element.classList.add('play');
                video.play();
            });
        }
    });
}

function openSubmenu() {
    const menu = body.querySelector('.nav-main-desktop');
    const menuItems = menu.querySelectorAll('.menu-item-has-children');

    menuItems.forEach(item => {
        const arrow = item.querySelector('.open-sumbenu');
        const subMenu = item.querySelector('.sub-menu-wrapper');

        arrow.addEventListener('click', ()=>{
            if (item.classList.contains('menu-item')) {

                menuItems.forEach(element => {
                    const secondMenu = element.querySelector('.sub-menu-wrapper');
                    if (subMenu != secondMenu) {
                        secondMenu.classList.remove('show');                        
                    }
                });                    
            }
            subMenu.classList.toggle('show');
        })
    });
}

function burgerAction() {
    const header = body.querySelector('.header');
    const burger = header.querySelector('.js-burger');

    burger.addEventListener('click', ()=>{
        header.classList.toggle('open');
        body.classList.toggle('no-scroll');
    })
}